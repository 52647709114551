body {
    margin: 0px;
    padding: 0px;
}
.search-box{
    /* border: 2px solid red; */
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search{
    /* text-align: center; */
    padding: 10px 15px;
    /* margin: 20px auto; */
    /* border: 2px solid #ccc; */
    border: none;
    min-width: 300px;
    color: rgb(121, 121, 121);
    border-radius: 5px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
}
.search:focus{
    outline: none;
}

.images{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}